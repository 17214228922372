import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { graphql } from "gatsby"
import Img from "gatsby-image"

import Content from '../.';

const NotFoundPage = ({ data }) => (
  <Layout>
      <SEO title="404: Not found" />
      <br/>
      <div align='center'>
        <div style={{ maxWidth: `500px`}}>
          <Img
            title="404 image"      
            alt="Minions hiding behind bushes."
            fluid={data.file.childImageSharp.fluid}
          />
        </div>
      </div>
      <br/>
      <div align='center'>
        <Content.Title>404: Page Not Found</Content.Title>
      </div>
  </Layout>
)

export default NotFoundPage

export const query = graphql`  
  query {    
    file(relativePath: {eq: "404.png"}) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
